import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';

import { adminRights as adminRightsConstant } from 'utils/constants';
import { activateAllRights } from 'utils/functions';

import { HelpTooltip, Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  Button,
  Checkbox,
  CustomSelect,
  Select,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  emailError: false,
  firstNameError: false,
  lastNameError: false,
  rolesError: false,
  productAccessError: false
};

const defaultRight = { ...adminRightsConstant };

const getSendCreationEmail = (
  userSendCreationEmail,
  domainSendCreationEmail
) => {
  if (typeof userSendCreationEmail === 'boolean') {
    return userSendCreationEmail;
  }
  if (typeof domainSendCreationEmail === 'boolean') {
    return domainSendCreationEmail;
  }
  return true;
};

export const ModalForm = props => {
  const { config, onValidate, onClose } = props;

  const { domainConfig } = useApp();
  const {
    selectedUser = {},
    rolesList,
    groupsList,
    adminPresetList,
    domainList
  } = config;
  const { graviteeId } = selectedUser;
  const { themeColors } = useTheme();
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const initialState = {
    firstName: selectedUser.given_name || '',
    lastName: selectedUser.family_name || '',
    email: selectedUser.email || '',
    roles: selectedUser.roles?.[0]?.id || [],
    groups: selectedUser.groups || [],
    adminRights: selectedUser.adminRights?.rights || defaultRight,
    adminRightsPreset: null,
    productAccess: selectedUser.productAccess || [],
    domains: selectedUser.domains || [],
    sendCreationEmail: getSendCreationEmail(
      selectedUser.sendCreationEmail,
      domainConfig?.features?.sendCreationEmail
    )
  };

  const [
    {
      firstName,
      lastName,
      email,
      roles,
      groups,
      adminRights,
      adminRightsPreset,
      productAccess,
      sendCreationEmail,
      domains
    },
    setState
  ] = useState(initialState);
  const [
    {
      emailError,
      firstNameError,
      lastNameError,
      rolesError,
      productAccessError
    },
    setError
  ] = useState(ErrorState);

  const capitalyse = str => {
    return str[0].toUpperCase() + str.substr(1);
  };

  const stringPathToObject = (object, keysStr) => {
    let keysString = '';
    keysString = keysStr.replace(/\[(\w+)\]/g, '.$1');
    keysString = keysStr.replace(/^\./, '');
    const keys = keysString.split('.');

    let objectReturn = object;
    for (let i = 0, len = keys.length; i < len; i += 1) {
      const key = keys[i];
      if (objectReturn[key] !== undefined) {
        objectReturn = objectReturn[key];
      } else {
        objectReturn[key] = {};
        objectReturn = objectReturn[key];
      }
    }
    return objectReturn;
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const handleChangeAccess = itemKey => value => {
    const newAccess = {
      product: itemKey,
      access: value
    };
    const index = productAccess.findIndex(el => el.product === itemKey);
    if (index === -1) {
      productAccess.push(newAccess);
    } else {
      productAccess[index] = newAccess;
    }
    setState(prevState => {
      const states = { ...prevState };
      states.productAccess = productAccess;
      return states;
    });
  };

  const handleChangePreset = value => {
    if (value === 'selectAll') {
      const allRights = activateAllRights(defaultRight);
      setState(prevState => {
        return {
          ...prevState,
          adminRightsPreset: value,
          adminRights: allRights
        };
      });
    } else {
      const rights = adminPresetList.find(el => el.id === value)?.rights;
      setState(prevState => {
        return { ...prevState, adminRightsPreset: value, adminRights: rights };
      });
    }
  };

  const handleCheckBoxChange = (masterKey, key) => () => {
    setState(prevState => {
      const states = { ...prevState };
      const oldState = stringPathToObject(states.adminRights, masterKey);
      oldState[key] = !oldState[key];
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
    resetErrors();
  };

  const doAction = async () => {
    let error = false;
    resetErrors();
    const datas = {
      firstName,
      lastName,
      email,
      roles: [roles],
      groups,
      adminRights,
      productAccess,
      additionalInformation: {
        domains: domains.filter(el => el !== 'selectAll')
      }
    };

    // Si jamais on ne veut pas flagger l'user si la valeur est la même que pour le domain
    // if (
    //   sendCreationEmail !== initialState.sendCreationEmail ||
    //   selectedUser.sendCreationEmail
    // ) {
    //   datas.sendCreationEmail = sendCreationEmail;
    // }

    setEmailErrorMessage('');
    if (!firstName) {
      toggleError('firstName', true);
      error = true;
    }
    if (!lastName) {
      toggleError('lastName', true);
      error = true;
    }
    if (!email) {
      toggleError('email', true);
      error = true;
    }
    if (roles.length === 0) {
      toggleError('roles', true);
      error = true;
    }
    if (productAccess.length !== 2) {
      toggleError('productAccess', true);
      error = true;
    }

    if (!error) {
      try {
        await onValidate(datas);
        resetStates();
      } catch (err) {
        setEmailErrorMessage(
          'Cette adresse email est déjà utilisée. Choisissez en une autre.'
        );
        toggleError('email', true);
      }
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {graviteeId ? 'Modifier' : 'Créer'} l&lsquo;utilisateur
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const getCheckbox = (masterKey, sectionkeys) => {
    return sectionkeys.map(key => {
      return (
        <Row spacing={0}>
          <Item flex spacing={0}>
            <Checkbox
              style={{ padding: '1px' }}
              size="small"
              checked={
                stringPathToObject(adminRights, masterKey)?.[key] || false
              }
              onChange={handleCheckBoxChange(masterKey, key)}
            />
          </Item>
          <Item xs justify="flex-start" spacing={0}>
            <Text>
              {key === 'advanced' ? 'Advanced (restricted)' : capitalyse(key)}
            </Text>
          </Item>
        </Row>
      );
    });
  };

  const AdminRightSection = configAdminRight => {
    const { titleSection, masterKey, sectionkeys } = configAdminRight;
    return (
      <Item
        justify="flex-start"
        xs={4}
        style={{ alignContent: 'flex-start', marginBottom: '20px' }}
      >
        <Row spacing={0}>
          <Item justify="flex-start">
            <Text bold>{titleSection}</Text>
          </Item>
        </Row>
        {getCheckbox(masterKey, sectionkeys)}
      </Item>
    );
  };

  const ramsesAccess = [
    {
      label: 'Accès interdit',
      value: 'blocked'
    },
    {
      label: 'Accès illimité',
      value: 'unlimited'
    }
  ];

  const ermesAccess = [
    ...ramsesAccess,
    {
      label: 'Abonné',
      value: 'subscriber'
    }
  ];

  const selectedRole = rolesList.find(el => el.id === roles)?.name;
  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Prénom"
                            required
                            small
                            label=""
                            onChange={handleChange('firstName')}
                            value={firstName}
                            error={firstNameError}
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Nom"
                            required
                            small
                            label=""
                            onChange={handleChange('lastName')}
                            value={lastName}
                            error={lastNameError}
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Email"
                            required
                            small
                            label=""
                            onChange={handleChange('email')}
                            value={email}
                            error={emailError}
                            errorText={emailErrorMessage}
                            disabled={!!graviteeId}
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Role"
                            required
                            onChange={handleChange('roles')}
                            options={rolesList.map(el => ({
                              label: el.name,
                              value: el.id
                            }))}
                            error={rolesError}
                            value={roles}
                            small
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text style={{ paddingRight: '5px' }}>
                      Envoi d’email à la création utilisateur
                    </Text>
                    <HelpTooltip
                      style={{ padding: '10px' }}
                      title={`L’utilisateur ne recevra pas d’email lors de sa création
                        pour setuper son mot de passe et ne pourra pas le reset.`}
                    />
                    <Container style={{ width: 'auto', paddingLeft: '5px' }}>
                      <Switch
                        title=""
                        checked={sendCreationEmail}
                        onChange={handleChange('sendCreationEmail')}
                        options={['Non', 'Oui']}
                        disabled={Object.keys(selectedUser).length !== 0}
                      />
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Accès aux produits" xs={3}>
              <Container>
                {selectedRole === 'ADMIN' && (
                  <Row spacing={0} justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <CustomSelect
                            small
                            title="Domain"
                            options={domainList}
                            selected={domainList.filter(el =>
                              domains.includes(el.key)
                            )}
                            onChange={values => {
                              const newValues = values.map(value => value.key);
                              // certain ancien utilisateur n'on pas de domain en db et l'option
                              // lean de la methode getAll de l'api n'applique pas le default
                              const userDomain =
                                selectedUser?.domain || 'ERMES';
                              if (!newValues.includes(userDomain)) {
                                newValues.push(userDomain);
                              }
                              handleChange('domains')(
                                values.map(value => value.key)
                              );
                            }}
                            selectAll
                            hideNone
                            width="382px"
                            searchBar
                            type="domain"
                            textColor={themeColors.global}
                            limit={20}
                          />
                        </Item>
                      </Row>
                    </Item>
                  </Row>
                )}
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Ermes"
                            required
                            onChange={handleChangeAccess('ermes')}
                            options={ermesAccess}
                            error={productAccessError}
                            value={
                              productAccess.find(el => el.product === 'ermes')
                                ?.access
                            }
                            small
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Ramses"
                            required
                            onChange={handleChangeAccess('ramses')}
                            options={ramsesAccess}
                            error={productAccessError}
                            value={
                              productAccess.find(el => el.product === 'ramses')
                                ?.access
                            }
                            small
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Organisation" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={groupsList.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={groups.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      onChange={handleChange('groups')}
                      actionTxt="Ajouter une organisation"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {selectedRole === 'ADMIN' && (
          <Row spacing={1}>
            <Item justify="flex-start">
              <FormSection title="Droits de l'utilisateur" xs={3}>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Preset"
                            onChange={handleChangePreset}
                            options={[
                              { label: 'Tous les droits', value: 'selectAll' },
                              ...adminPresetList.map(el => ({
                                label: el.name,
                                value: el.id
                              }))
                            ]}
                            value={adminRightsPreset}
                            small
                            bgColor={themeColors.primary}
                            defaultValue="Sélectionnez un preset"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row
                  spacing={0}
                  justify="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Item justify="flex-start">
                    <Text bold fontSize="16px">
                      Funnel
                    </Text>
                  </Item>
                  <Item xs={10} alignItems="flex-start" justify="flex-start">
                    <AdminRightSection
                      titleSection="Géneral"
                      masterKey="funnel.funnel"
                      sectionkeys={Object.keys(defaultRight.funnel.funnel)}
                    />
                    <AdminRightSection
                      titleSection="Models"
                      masterKey="funnel.models"
                      sectionkeys={Object.keys(defaultRight.funnel.models)}
                    />
                    <AdminRightSection
                      titleSection="Data"
                      masterKey="funnel.data"
                      sectionkeys={Object.keys(defaultRight.funnel.data)}
                    />

                    <AdminRightSection
                      titleSection="Médias"
                      masterKey="funnel.medias"
                      sectionkeys={Object.keys(defaultRight.funnel.medias)}
                    />
                    <AdminRightSection
                      titleSection="Propriétés"
                      masterKey="funnel.properties"
                      sectionkeys={Object.keys(defaultRight.funnel.properties)}
                    />
                    <AdminRightSection
                      titleSection="Modèles"
                      masterKey="funnel.funnelStep"
                      sectionkeys={Object.keys(defaultRight.funnel.funnelStep)}
                    />
                    <AdminRightSection
                      titleSection="Metric Yougov"
                      masterKey="funnel.yougovMetrics"
                      sectionkeys={Object.keys(
                        defaultRight.funnel.yougovMetrics
                      )}
                    />
                    <AdminRightSection
                      titleSection="Anomalies"
                      masterKey="funnel.anomalies"
                      sectionkeys={Object.keys(defaultRight.funnel.anomalies)}
                    />
                    <AdminRightSection
                      titleSection="Connecteurs"
                      masterKey="funnel.connectors"
                      sectionkeys={Object.keys(defaultRight.funnel.connectors)}
                    />
                    <AdminRightSection
                      titleSection="Boucle Retour Data"
                      masterKey="funnel.dataFeedback"
                      sectionkeys={Object.keys(
                        defaultRight.funnel.dataFeedback
                      )}
                    />
                    <AdminRightSection
                      titleSection="Alerting"
                      masterKey="funnel.alert"
                      sectionkeys={Object.keys(defaultRight.funnel.alert)}
                    />
                    <AdminRightSection
                      titleSection="Export CSV BO"
                      masterKey="funnel.backOfficeCsvExport"
                      sectionkeys={Object.keys(
                        defaultRight.funnel.backOfficeCsvExport
                      )}
                    />
                  </Item>
                </Row>
                <Row
                  spacing={0}
                  justify="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Item justify="flex-start">
                    <Text bold fontSize="16px">
                      Audiences
                    </Text>
                  </Item>
                  <Item xs={10} alignItems="flex-start" justify="flex-start">
                    <AdminRightSection
                      titleSection="Critères"
                      masterKey="audiences.criteria"
                      sectionkeys={Object.keys(defaultRight.audiences.criteria)}
                    />
                    <AdminRightSection
                      titleSection="Catégories"
                      masterKey="audiences.categories"
                      sectionkeys={Object.keys(
                        defaultRight.audiences.categories
                      )}
                    />
                    <AdminRightSection
                      titleSection="TagFunnel"
                      masterKey="audiences.tagFunnel"
                      sectionkeys={Object.keys(
                        defaultRight.audiences.tagFunnel
                      )}
                    />
                    <AdminRightSection
                      titleSection="Audiences Activées"
                      masterKey="audiences.activatedAudience"
                      sectionkeys={Object.keys(
                        defaultRight.audiences.activatedAudience
                      )}
                    />
                    <AdminRightSection
                      titleSection="Export Segment"
                      masterKey="audiences.exportSegment"
                      sectionkeys={Object.keys(
                        defaultRight.audiences.exportSegment
                      )}
                    />
                    <AdminRightSection
                      titleSection="Partenaires"
                      masterKey="audiences.partners"
                      sectionkeys={Object.keys(defaultRight.audiences.partners)}
                    />
                    <AdminRightSection
                      titleSection="Export Media"
                      masterKey="audiences.exportMedia"
                      sectionkeys={Object.keys(
                        defaultRight.audiences.exportMedia
                      )}
                    />
                  </Item>
                </Row>
                <Row
                  spacing={0}
                  justify="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Item justify="flex-start">
                    <Text bold fontSize="16px">
                      Ramses
                    </Text>
                  </Item>
                  <Item xs={10} alignItems="flex-start" justify="flex-start">
                    <AdminRightSection
                      titleSection="Rapports"
                      masterKey="ramses.reports"
                      sectionkeys={Object.keys(defaultRight.ramses.reports)}
                    />
                    <AdminRightSection
                      titleSection="Propriétés"
                      masterKey="ramses.properties"
                      sectionkeys={Object.keys(defaultRight.ramses.properties)}
                    />
                  </Item>
                </Row>
                <Row
                  spacing={0}
                  justify="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Item justify="flex-start">
                    <Text bold fontSize="16px">
                      Général
                    </Text>
                  </Item>
                  <Item xs={10} alignItems="flex-start" justify="flex-start">
                    <AdminRightSection
                      titleSection="Dashboard"
                      masterKey="general.dashboard"
                      sectionkeys={Object.keys(defaultRight.general.dashboard)}
                    />
                    <AdminRightSection
                      titleSection="Organisations"
                      masterKey="general.groups"
                      sectionkeys={Object.keys(defaultRight.general.groups)}
                    />
                    <AdminRightSection
                      titleSection="Utilisateurs"
                      masterKey="general.users"
                      sectionkeys={Object.keys(defaultRight.general.users)}
                    />
                    <AdminRightSection
                      titleSection="Connection"
                      masterKey="general.connection"
                      sectionkeys={Object.keys(defaultRight.general.connection)}
                    />
                    <AdminRightSection
                      titleSection="Administrateurs"
                      masterKey="general.admin"
                      sectionkeys={Object.keys(defaultRight.general.admin)}
                    />
                    <AdminRightSection
                      titleSection="Droit des administrateurs"
                      masterKey="general.adminRights"
                      sectionkeys={Object.keys(
                        defaultRight.general.adminRights
                      )}
                    />
                    <AdminRightSection
                      titleSection="Connector"
                      masterKey="general.connector"
                      sectionkeys={Object.keys(defaultRight.general.connector)}
                    />
                    <AdminRightSection
                      titleSection="Domaine"
                      masterKey="general.domain"
                      sectionkeys={Object.keys(defaultRight.general.domain)}
                    />
                    <AdminRightSection
                      titleSection="Médias activables"
                      masterKey="general.activableMedias"
                      sectionkeys={Object.keys(
                        defaultRight.general.activableMedias
                      )}
                    />
                    <AdminRightSection
                      titleSection="Clients"
                      masterKey="general.customers"
                      sectionkeys={Object.keys(defaultRight.general.customers)}
                    />
                    <AdminRightSection
                      titleSection="Tarification"
                      masterKey="general.tarification"
                      sectionkeys={Object.keys(
                        defaultRight.general.tarification
                      )}
                    />
                    <AdminRightSection
                      titleSection="Logs"
                      masterKey="logs"
                      sectionkeys={Object.keys(defaultRight.logs)}
                    />
                    <AdminRightSection
                      titleSection="God mode"
                      masterKey="godMode"
                      sectionkeys={Object.keys(defaultRight.godMode)}
                    />
                  </Item>
                </Row>
                <Row
                  spacing={0}
                  justify="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Item justify="flex-start">
                    <Text bold fontSize="16px">
                      PML
                    </Text>
                  </Item>
                  <Item xs={10} alignItems="flex-start" justify="flex-start">
                    <AdminRightSection
                      titleSection="Configuration"
                      masterKey="pml.config"
                      sectionkeys={Object.keys(defaultRight.pml.config)}
                    />
                    <AdminRightSection
                      titleSection="Points de ventes"
                      masterKey="pml.shop"
                      sectionkeys={Object.keys(defaultRight.pml.shop)}
                    />
                    <AdminRightSection
                      titleSection="Groupes de magasins"
                      masterKey="pml.shopGroup"
                      sectionkeys={Object.keys(defaultRight.pml.shopGroup)}
                    />
                    <AdminRightSection
                      titleSection="Newsletter"
                      masterKey="pml.newsletter"
                      sectionkeys={Object.keys(defaultRight.pml.newsletter)}
                    />
                  </Item>
                </Row>
                <Row
                  spacing={0}
                  justify="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Item justify="flex-start">
                    <Text bold fontSize="16px">
                      Activation
                    </Text>
                  </Item>
                  <Item xs={10} alignItems="flex-start" justify="flex-start">
                    <AdminRightSection
                      titleSection="Presets"
                      masterKey="activation"
                      sectionkeys={Object.keys(defaultRight.activation)}
                    />
                    <AdminRightSection
                      titleSection="Campagnes"
                      masterKey="campaign.campaign"
                      sectionkeys={Object.keys(defaultRight.campaign.campaign)}
                    />
                  </Item>
                </Row>
              </FormSection>
            </Item>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null,
  onValidate: () => {}
};
ModalForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onValidate: PropTypes.func,
  config: PropTypes.objectOf({
    groupsList: PropTypes.arrayOf(PropTypes.any).isRequired,
    adminPresetList: PropTypes.arrayOf(PropTypes.any).isRequired,
    rolesList: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedUser: PropTypes.arrayOf(PropTypes.any)
  }).isRequired
};

export default ModalForm;
